import React, { useEffect, useState } from 'react';
import { AdminNav } from '../NavBar/AdminNav';
import api from '../api';

export const DealerListing = () => {
    const [dealerlisting, setDealerListing] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get('/dealer/dealerlist');
            if (response.data.Dealer && Array.isArray(response.data.Dealer)) {
                setDealerListing(response.data.Dealer);
            } else {
                console.error('Data is not in the expected format:', response.data);
                setDealerListing([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const Postdata = async (dealerId) => {
        try {
            await api.post(`/admin/dealeractivatedeactivate/${dealerId}`);
            fetchData();
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const Postdataavtivate = async (dealerId, dealer_varify) => {
        try {
            await api.post(`/admin/dealervarificaion/${dealerId}`, { dealer_varify });
            fetchData();
        } catch (error) {
            console.error('Error updating dealer status:', error);
        }
    };

    return (
        <section className="dealer-listing">
            <AdminNav />
            <div className="mid-panel mt-3">
                <div className="container">
                    <h3 className="main-heading">Dealer Listing</h3>
                    {loading ? (
                        <p>Loading...</p> 
                    ) : dealerlisting.length === 0 ? (
                        <div className="col-md-12">
                            <p className="text-center text-danger">No Dealer Found</p>
                        </div>
                    ) : (
                        <div className="flip-card">
                            <div className="row">
                                {dealerlisting.map((item) => (
                                    <div className="col-lg-4 col-md-6 pt-3" key={item.Dealer_Id}>
                                        <div className="card card-simple">
                                            <div className="card-front text-white badge badge-primary">
                                                <div className="card-body">
                                                    <h6 className="card-title">{item.Dealer_Fname} {item.Dealer_Lname}</h6>
                                                    <h6 className="card-title">{item.Dealer_Address}</h6>
                                                    <h6 className="card-title">{item.Dealership_ID}</h6>
                                                    <h6 className="card-title">{item.Dealer_Phone}</h6>
                                                    <h5 className="card-title">{item.Dealer_Status === 1 ? "Active" : "Inactive"}</h5>
                                                    <div className="text-center">
                                                        {item.Dealer_Signup_Status === 0 && item.Dealer_Status === 1 && (
                                                            <>
                                                                <button
                                                                    className="btn btn-sm btn-outline-light px-2 py-2 mt-2 mr-3"
                                                                    onClick={() => Postdataavtivate(item.Dealer_Id, "1")}
                                                                >
                                                                    Verify
                                                                </button>
                                                                <button
                                                                    className="btn btn-sm btn-outline-light px-2 py-2 mt-2"
                                                                    onClick={() => Postdataavtivate(item.Dealer_Id, "2")}
                                                                >
                                                                    Disallow
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="text-center">
                                                        {item.Dealer_Signup_Status === 1 && (
                                                            item.Dealer_Status === 1 ? (
                                                                <button className="btn btn-sm btn-outline-light px-2 py-2 mt-2" onClick={() => Postdata(item.Dealer_Id)}>Deactivate</button>
                                                            ) : (
                                                                <button className="btn btn-sm btn-outline-light px-2 py-2 mt-2" onClick={() => Postdata(item.Dealer_Id)}>Activate</button>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
